import Cookies from '/assets/js/js-cookie.js';

// Cookies.set('cookies_accpeted', 0);
const getCookies = Cookies.get('cookies_accpeted');

$(document).ready(function () {
  const privacyPolicyBar = $('#cookie_policy');
  const privacyPolicyBarButton = $('#cookie_policy_accept');
  const scrollToTop = $('#scrollToTop');

  if (getCookies != '1') {
    privacyPolicyBar.addClass('show');
    scrollToTop.addClass('privacyActive');
  }

  privacyPolicyBarButton.on('click', function () {
    Cookies.set('cookies_accpeted', 1);
    privacyPolicyBar.removeClass('show');
    scrollToTop.removeClass('privacyActive');
  });
});
